<template>
  <div
    :style="widthStyles"
    class="a-input"
  >
    <input
      :class="{
        'a-input--hide-number-controls': hideNumberControls,
        'a-input--has-icon-right': $attrs.type === 'search' || iconRight,
        'a-input--has-icon-left': iconLeft,
      }"
      v-bind="$attrs"
      v-model="_input"
      :data-automation-testing="`in${name}${$attrs.type}`"
    />
  </div>
</template>

<script>
export default {
  name: 'a-input',
  inheritAttrs: false,
  props: {
    hideNumberControls: Boolean,
    /**
     * Disabled state, won't allow input to change
     */
    iconRight: String,
    /**
     * When provided, receives a relative path to an icon defined in the assets/icons directory for use in input. Icon appears to the left inside the text field
     */
    iconLeft: String,
    /**
     * Current value of the field
     */
    modelValue: [String, Number],
    /**
     * Control width of the input
     */
    width: String,
    /**
     * Defines the name of the component for automation testing usage
     */
    name: {
      type: String,
      default: 'dnn',
    },
  },
  computed: {
    _input: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    widthStyles() {
      return this.width
        ? {
            width: this.width,
          }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
$color-grey-10: #fbfbfb;
$color-purple-20: #f5f2fb;
$color-purple-30: #eee9f8;
$color-grey-20: #f3f3f3;
$color-grey-30: #e1e1e1;
$color-grey-40: #adadad;
$color-grey-50: #747474;
$color-primary-dark: #333333;
$color-primary-purple: #662e80;

.a-input {
  display: flex;
  position: relative;

  .a-input__icon-left,
  .a-input__icon-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 40px;
  }

  .a-input__icon-left {
    left: 0px;

    img {
      left: 3px;
    }
  }

  .a-input__icon-right {
    right: 0px;

    img {
      right: 3px;
    }
  }

  input {
    width: 100%;
    padding: 12px;
    padding-bottom: 11px;
    background-color: var(--dscl-forms-bg, #{$color-grey-10});
    border: none;
    border-bottom: 1px solid var(--dscl-forms-underline, #{$color-grey-50});
    border-radius: 4px 4px 0 0;
    transition: 0.2s ease;
    color: var(--dscl-dark-font, #{$color-primary-dark});
    transition: background-color 0.5s;

    &[type='search'] {
      appearance: none;
      -webkit-appearance: none;
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }

    &::placeholder {
      color: var(--dscl-forms-placeholder-font, #{$color-grey-40});
    }

    &:focus {
      border-bottom-color: var(--dscl-forms-underline-focus, var(--dscl-primary, #{$color-primary-purple}));
      outline: none;
    }

    &:hover {
      background-color: var(--dscl-forms-hover, #{$color-grey-20});
    }

    &:disabled,
    &:disabled::placeholder {
      background-color: var(--dscl-disabled, #{$color-grey-20});
      border-color: var(--dscl-disabled, #{$color-grey-20});
      color: var(--dscl-disabled-font, #{$color-grey-30});
    }

    &.a-input--has-icon-left {
      padding-left: 36px;
    }

    &.a-input--has-icon-right {
      padding-right: 36px;
    }

    &.a-input--hide-number-controls {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
</style>
