<template>
  <button
    :type="type"
    :class="[
      'a-button',
      `a-button--${variant}`,
      `a-button--mode-${mode}`,
      `a-button--size-${size}`,
      selected && `is-selected`,
    ]"
    :disabled="disabled"
    :style="`width: ${width}`"
    :data-automation-testing="`btn${name}`"
  >
    <div
      v-if="$slots['icon-left'] || iconLeft"
      class="a-button__icon a-button__icon--left"
      :data-automation-testing="`btnIconLeft${name}`"
    >
      <slot name="icon-left"></slot>
    </div>

    <span class="a-button-container"><slot></slot></span>

    <div
      v-if="$slots['icon-right'] || iconRight"
      class="a-button__icon a-button__icon--right"
      :data-automation-testing="`btnIconRight${name}`"
    >
      <slot name="icon-right"></slot>
    </div>
  </button>
</template>

<script>
const BUTTON_VARIANTS = ['primary', 'secondary', 'tertiary', 'link', 'select', 'none'];
const BUTTON_MODES = ['default', 'error', 'success', 'warning', 'info'];

export default {
  name: 'a-button',
  props: {
    /**
     * Defines the type of the Button. The button component will behave as per spec, eg, if a button is wrapped in a form it will submit the form by default, to prevent this, set `type="button"` on the button.
     * @values button, submit, reset
     */
    type: {
      type: String,
    },
    /**
     * Defines the variant of the Button.
     * @values default, primary, secondary, tertiary, link, none
     */
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => BUTTON_VARIANTS.includes(value),
    },
    /**
     * Changes the intent and color of the Button
     * @values default, error, success, warning, info
     */
    mode: {
      type: String,
      default: 'default',
      validator: (value) => BUTTON_MODES.includes(value),
    },
    /**
     * Changes size of the Button
     * @values small, medium
     */
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium'].includes(value),
    },
    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Selected state
     */
    selected: {
      type: Boolean,
      default: false,
    },
    /**
     * When provided, receives a relative path to an icon defined in the assets/icons directory for use in button component. Icon appears to the right
     */
    iconRight: {
      type: String,
    },
    /**
     * When provided, receives a relative path to an icon defined in the assets/icons directory for use in button component. Icon appears to the left
     */
    iconLeft: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    /**
     * Define a fixed width for the botton. Useful when content is dinamyc and we dont want the button to drastically change.
     */
    width: {
      type: String,
    },
    /**
     * Defines the name of the component for automation testing usage
     */
    name: {
      type: String,
      default: 'dnn',
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$el.focus();
    }
  },
};
</script>

<style lang="scss" scoped>
$font-size-base: 14px;
$color-primary-dark: #333333;
$color-primary-white: #ffffff;
$color-primary-purple: #662e80;
$color-red: #cc314b;
$color-red-20: #faeaed;
$color-red-30: #e8adb7;
$color-red-60: #8d2a3d;
$color-teal: #3bb5b5;
$color-grey-20: #f3f3f3;
$color-grey-50: #747474;
$color-teal-10: rgba(236, 248, 248, 0.1);
$color-teal-20: #f3fbfb;
$color-teal-30: #ceecec;
$color-teal-40: #58c0c0;
$color-teal-50: #3aa8a8;
$color-teal-60: #388181;
$color-teal-70: #2a5a5a;
$color-purple-20: #f5f2fb;
$color-purple-30: #eee9f8;
$color-purple-60: #473152;
$font-family: 'Neue Haas Grotesk Disp', 'Inter', 'Helvetica Neue', Arial, sans-serif;

@function font-shorthand($font-size, $font-line-height, $font-weight) {
  @return #{$font-weight} #{$font-size}/#{$font-line-height} $font-family;
}

.a-button {
  --button-tertiary-bg: var(--dscl-light, #{$color-primary-white});
  --button-primary-text: var(--dscl-light-font, #{$color-primary-white});

  &--mode-default {
    --button-primary-bg: var(--dscl-primary, #{$color-primary-purple});
    --button-primary-bg-hover: var(--dscl-primary-hover, #{$color-purple-60});
    --button-alt-bg: var(--dscl-alt-color, #{$color-purple-20});
    --button-alt-bg-hover: var(--dscl-alt-hover, #{$color-purple-30});
    --button-alt-text: var(--dscl-primary, #{$color-primary-purple});
  }

  &--mode-error {
    --button-primary-bg: var(--dscl-error-color, #{$color-red});
    --button-primary-bg-hover: var(--dscl-error-hover, #{$color-red-60});
    --button-alt-bg: var(--dscl-error-alt-color, #{$color-red-20});
    --button-alt-bg-hover: var(--dscl-error-alt-hover, #{$color-red-30});
    --button-alt-text: var(--dscl-error-alt-font-color, #{$color-red});
  }

  &--mode-success {
    --button-primary-bg: var(--dscl-success-color, #{$color-teal});
    --button-primary-bg-hover: var(--dscl-success-hover, #{$color-teal-60});
    --button-alt-bg: var(--dscl-success-alt-color, #{$color-teal-20});
    --button-alt-bg-hover: var(--dscl-success-alt-hover, #{$color-teal-30});
    --button-alt-text: var(--dscl-success-alt-font-color, #{$color-teal});
  }

  font: font-shorthand($font-size-base, 20px, 400);
  line-height: 16px;
  border-radius: 3px;
  border: none;
  outline: none;
  background: none;
  user-select: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .a-button-container {
    gap: 8px;
    width: 100%;
  }

  &:focus:not(:focus-visible) {
    &:before {
      border: none;
    }
  }

  &:focus {
    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      height: calc(100% + 6px);
      width: calc(100% + 6px);
      border: 1px solid #662e80;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
  &:disabled {
    background-color: var(--dscl-disabled, #{$color-grey-20});
    color: var(--dscl-disabled-font, #{$color-grey-50});
    cursor: default;
    outline: none;
    &:hover {
      background-color: var(--dscl-disabled, #{$color-grey-20});
    }
  }
  &__icon {
    font: font-shorthand(14px, 20px, 500);
    vertical-align: middle;
    line-height: 16px;
  }

  &--size {
    &-medium {
      line-height: 20px;
      padding: 6px 16px;
      &.a-button--tertiary {
        padding: 5px 15px;
      }
    }

    &-small {
      line-height: 16px;
      padding: 4px 8px;
      &.a-button--tertiary {
        padding: 3px 7px;
      }
    }
  }

  &--primary {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
    &:hover {
      background-color: var(--button-primary-bg-hover);
    }
  }

  &--secondary {
    background-color: var(--button-alt-bg);
    color: var(--button-alt-text);
    &:hover {
      background-color: var(--button-alt-bg-hover);
    }
  }

  &--tertiary {
    background-color: var(--button-tertiary-bg);
    color: var(--button-alt-text);
    border: 1px solid var(--button-alt-bg);
    &:hover {
      background-color: var(--button-alt-bg-hover);
      border: 1px solid var(--button-alt-bg-hover);
    }
  }

  &--link {
    background-color: transparent;
    border: none;
    color: var(--button-alt-text);
    font-size: 16px;
    padding: 0;
    &:hover:not([disabled]) {
      color: var(--button-primary-bg-hover);
    }
    &:disabled {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
  &--select {
    background-color: var(--dscl-light, #{$color-primary-white});
    border: 1px solid var(--button-alt-bg-hover);
    line-height: 16px;
    padding: 8px 12px;
    color: var(--dscl-dark-font, #{$color-primary-dark});
    &.is-selected {
      border: 1px solid #662e80;
    }
    &:hover:not([disabled]) {
      background-color: #f5f2fb;
      border: 1px solid #e8adb7;
    }
    &:disabled {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
</style>
