<template>
  <div
    v-bind="$attrs"
    :class="['a-card', hover && 'a-card--hover', flat && 'a-card--flat']"
    :style="[sizeStyles(), paddingStyles()]"
    :data-automation-testing="`btnCard${name}`"
  >
    <slot />
  </div>
</template>

<script>
function convertToUnit(value, unit = 'px') {
  if (value == null || value === '') {
    return undefined;
  }

  if (Number.isNaN(Number(value)) || Number(value) === 0) {
    return String(value);
  }

  return `${Number(value)}${unit}`;
}

function validateDeprecateProp(value) {
  if (value !== null) {
    console.warn(`DSCL: Cards can now be styled directly with either classes or inline styles.`);
  }
}

export default {
  name: 'a-card',

  props: {
    /**
     * Defines the name of the component for automation testing usage
     */
    name: {
      type: String,
      default: 'dnn',
    },
    /**
     * Changes elevation on shadow on hover
     */
    hover: {
      type: Boolean,
      default: false,
    },
    /**
     * Renders card without shadow and a thin border
     */
    flat: {
      type: Boolean,
      default: false,
    },
    /**
     * @deprecated - Use inline styles or classes instead
     */
    width: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    /**
     * [Deprecated] Use inline styles or classes instead
     * @DEPRECATED Use inline styles or classes instead
     */
    minWidth: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    /**
     * Don't use this prop
     */
    maxWidth: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    height: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    minHeight: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    maxHeight: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    paddingTop: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    paddingRight: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    paddingBottom: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
    paddingLeft: {
      type: [String, Number],
      default: null,
      validator(value) {
        validateDeprecateProp(value);
        return true;
      },
    },
  },
  computed: {
    sizeStyles() {
      return () => ({
        width: convertToUnit(this.width),
        minWidth: convertToUnit(this.minWidth),
        maxWidth: convertToUnit(this.maxWidth),
        height: convertToUnit(this.height),
        minHeight: convertToUnit(this.minHeight),
        maxHeight: convertToUnit(this.maxHeight),
      });
    },
    paddingStyles() {
      return () => ({
        paddingTop: convertToUnit(this.paddingTop),
        paddingRight: convertToUnit(this.paddingRight),
        paddingBottom: convertToUnit(this.paddingBottom),
        paddingLeft: convertToUnit(this.paddingLeft),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a-card {
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
  border-radius: 4px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease-in-out;

  &--hover {
    &:hover {
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  &--flat {
    border: 1px solid #f5f2fb;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}
</style>
