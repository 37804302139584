<template>
  <div
    :class="['form-group', inlineCSSClass]"
    :style="marginStyles"
  >
    <div :class="['form-group__helpers', charCounterCSSClass, disabledCSSClass]">
      <div
        v-if="label"
        class="form-group__label-container"
      >
        <label
          :for="labelFor"
          :data-automation-testing="`lblFG${name}`"
          class="form-group__label"
          :class="{ 'form-group__label-required': required }"
        >
          {{ label }}
        </label>
        <span
          v-if="optional"
          class="form-group__optional"
          >Optional</span
        >
      </div>

      <div
        v-if="helperMessage"
        class="form-group__helper-message"
        :data-automation-testing="`lblFGHelper${name}`"
      >
        {{ helperMessage }}
      </div>

      <div
        v-if="charCounter"
        class="form-group__character-counter"
        :data-automation-testing="`lblFGCounter${name}`"
      >
        {{ charCounter }}
      </div>
    </div>

    <div
      class="form-group__feedback-wrapper"
      :style="$options.widthStyles(width)"
    >
      <div :class="['form-group__element', errorCSSClass, warningCSSClass, editCSSClass]">
        <slot></slot>

        <img
          v-if="showError && !hideErrorIcon"
          class="form-group__element-icon-error"
          src="../../../assets/error.svg"
          :data-automation-testing="`iconFGError${name}`"
        />
        <img
          v-else-if="showWarning"
          class="form-group__element-icon-warning"
          src="../../../assets/warning.svg"
          :data-automation-testing="`iconFGWarning${name}`"
          @click="handleOnWarningClick"
        />
        <img
          v-else-if="showEdit"
          class="form-group__element-icon-edit"
          src="../../../assets/pencil.svg"
          :data-automation-testing="`iconFGEdit${name}`"
          @click="handleOnEditClick"
        />
      </div>

      <div
        v-if="showError"
        class="form-group__error-message"
        :data-automation-testing="`lblFGError${name}`"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import MarginMixin from '../../../mixins/margin';
import WidthMixin from '../../../mixins/width';

export default {
  name: 'form-group',
  mixins: [MarginMixin, WidthMixin],
  props: {
    label: String,
    labelFor: String,
    required: Boolean,
    inline: Boolean,
    showError: Boolean,
    hideErrorIcon: Boolean,
    showWarning: Boolean,
    errorMessage: String,
    helperMessage: String,
    charCounter: String,
    disabled: Boolean,
    optional: Boolean,
    showEdit: Boolean,
    /**
     * Defines the name of the component for automation testing usage
     */
    name: {
      type: String,
      default: 'dnn',
    },
  },

  computed: {
    disabledCSSClass() {
      return this.disabled ? 'form-group__helpers--disabled' : '';
    },
    inlineCSSClass() {
      return this.inline ? 'form-group--inline' : '';
    },
    charCounterCSSClass() {
      return this.charCounter ? 'form-group__helpers--show-char-counter' : '';
    },
    errorCSSClass() {
      return this.showError ? 'form-group__element--has-error' : '';
    },
    warningCSSClass() {
      return this.showWarning ? 'form-group__element--has-warning' : '';
    },
    editCSSClass() {
      return this.showEdit ? 'form-group__element--has-edit' : '';
    },
  },
  methods: {
    handleOnWarningClick() {
      this.$emit('on-warning-click');
    },
    handleOnEditClick() {
      this.$emit('on-edit-click');
    },
  },
};
</script>

<style lang="scss" scoped>
$color-grey-30: #e1e1e1;
$color-grey-50: #747474;
$color-primary-dark: #333333;
$color-primary-purple: #662e80;
$color-red: #cc314b;
$font-family: 'Neue Haas Grotesk Disp', 'Inter', 'Helvetica Neue';

@function font-shorthand($font-size, $font-line-height, $font-weight) {
  @return #{$font-weight} #{$font-size}/#{$font-line-height} $font-family;
}

.form-group {
  margin-bottom: 24px;

  &.form-group--inline {
    display: flex;
    align-items: center;
  }
  &__helpers {
    position: relative;

    &--show-char-counter {
      padding-right: 50px;
    }

    &--disabled {
      .form-group__label,
      .form-group__helper-message,
      .form-group__character-counter {
        color: var(--dscl-disabled-font, #{$color-grey-30});
      }
    }
  }

  &__label {
    font: font-shorthand(14px, 20px, 500);
    color: var(--dscl-dark-font, #{$color-primary-dark});
  }

  &__optional {
    color: var(--dscl-forms-helper-font, #{$color-grey-50});
    letter-spacing: 0.4px;
  }

  &__error-wrapper {
    position: relative;
  }

  &__helper-message,
  &__error-message,
  &__character-counter {
    letter-spacing: 0.4px;
  }

  &__helper-message {
    margin: 4px 0 2px;
    color: var(--dscl-forms-helper-font, #{$color-grey-50});
  }

  &__error-message {
    color: var(--dscl-error-color, #{$color-red});
  }

  &__character-counter {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    color: var(--dscl-forms-helper-font, #{$color-grey-50});
  }

  &__required {
    color: var(--dscl-primary, #{$color-primary-purple});
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .form-group__label {
      &.form-group__label-required::after {
        content: '*';
        color: $color-red;
      }
    }
  }

  &__feedback-wrapper {
    .form-group--inline & {
      flex-grow: 1;
    }
    position: relative;

    .form-group__element {
      &-icon-error,
      &-icon-edit,
      &-icon-warning {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 1;
      }

      &-icon-edit,
      &-icon-warning {
        cursor: pointer;
      }

      &--has-error {
        textarea,
        input {
          border: 1px solid var(--dscl-error-color, #{$color-red});

          &:focus {
            border-color: var(--dscl-error-color, #{$color-red});
          }
        }
      }

      &--has-error,
      &--has-edit,
      &--has-warning {
        input {
          padding-right: 36px;
        }
      }
    }
  }
}
</style>
