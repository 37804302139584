export default {
  props: {
    margin: String,
  },

  computed: {
    marginStyles() {
      return this.margin ? { margin: this.margin } : {};
    },
  },
};
