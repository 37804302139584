<template>
  <FormGroup
    :label="label"
    :label-for="id"
    :name="name"
    :show-error="showError"
    :show-warning="showWarning"
    :show-edit="showEdit"
    :error-message="errorMessage"
    :helper-message="helperMessage"
    :char-counter="charCounter"
    :disabled="disabled"
    :required="required"
    :margin="margin"
    :optional="optional"
    :width="width"
    @on-warning-click="onWarningClick"
    @on-edit-click="onEditClick"
  >
    <div class="a-form-input">
      <a-input
        v-bind="$attrs"
        v-model="inputVal"
        :type="type"
        :id="id"
        :disabled="disabled"
        :maxLength="maxLength"
        @input="handleInput"
        @change="handleInput"
        :data-maska="$attrs['data-maska']"
      />
    </div>
  </FormGroup>
</template>

<script>
import Input from './Input.vue';
import FormGroup from './FormGroup.vue';

const typeSupportsMaxLength = (type) => ['password', 'search', 'tel', 'text', 'url'].includes(type);

export default {
  name: 'a-form-input',
  inheritAttrs: false,
  components: {
    'a-input': Input,
    FormGroup,
  },
  props: {
    modelValue: [String, Number],
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: String,
    disabled: Boolean,
    required: Boolean,
    hideNumberControls: Boolean,
    maxLength: [String, Number],
    textAlign: String,
    optional: Boolean,
    width: String,
    errorMessage: String,
    helperMessage: String,
    showCharCounter: Boolean,
    showError: Boolean,
    showWarning: Boolean,
    showEdit: Boolean,
    margin: String,
  },
  computed: {
    localMaxLength() {
      return typeSupportsMaxLength(this.type) ? this.maxLength : null;
    },
    textAlignCSSClass() {
      return this.textAlign;
    },
    charCounter() {
      if (this.showCharCounter) {
        const hasMax = this.localMaxLength ? `/ ${this.localMaxLength}` : '';
        return `${(this.modelValue && [...this.modelValue].length) || 0} ${hasMax}`;
      }
      return '';
    },
    inputVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    handleInput(e) {
      const normalizedEvent = e?.target ? e.target.value : e;
      this.inputVal = normalizedEvent;
    },
    onWarningClick() {
      this.$emit('on-warning-click');
    },
    onEditClick() {
      this.$emit('on-edit-click');
    },
  },
};
</script>
