export default {
  props: {
    width: String,
  },
  widthStyles(width) {
    return width ? { width } : {};
  },
  computed: {
    widthStyles() {
      return this.width ? { width: this.width } : {};
    },
  },
};
